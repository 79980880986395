* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

.NavBar {
    // max-width: 1980px;
    // background: rgb(113, 113, 113);
    background: grey;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0%;
    z-index: 1000;
    // height: 10vh;

    .name {
        display: flex;
        p {
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 500;
        }
        img {
            width: 2.5rem;
            height: 2.5rem;
        }
        margin-left: 4rem;
    }

    .menu-list {
        margin-right: 3rem;
    }
    
    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    
    li {
        list-style: none;
        margin: 1rem;

        div {
            color: black;
            text-decoration: none;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;
            &:hover {
                font-weight: 800;
            }
        }
        
        div::after {
            content: attr(data-text);
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: 800;
        }
    }
    

    
    button {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        background-color: white;
        border-color: black;
        color: black;
        &:hover {
            background-color: rgb(222, 222, 222);
            border-color: black;
            cursor: pointer;
            font-weight: 800;
        }
        a {
            font-size: 1.1rem;
            color: black;
            text-decoration: none;
        }
    }
    
    button::after {
        content: attr(data-text);
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: 800;
    }

    .burger {
        display: none;
    }

    @media screen and (max-width:1000px) {
        height: 6rem;

        .menu-list {
            position: absolute;
            right: 0%;
            margin: 0;
            height: calc(100vh - 4rem);
            top: 6rem;
            background-color: grey;
            width: 50%;
            transform: translateX(100%);
            transition: transform 0.5s ease-in;

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }    
        .burger {
            display: inline;
            margin-right: 4rem;
            cursor: pointer;
        }    
    }
    .nav-active {
        transform: translateX(0%);
    }

    @media screen and (max-width: 600px) {
        .name {
            margin-left: 2rem;
        }
        .burger {
            margin-right: 2rem;
        }
    }
}



