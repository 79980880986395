// .footer {
//     display: flex;
//     gap: 1rem;

//     min-height: 12rem;
//     background-color: rgb(113, 113, 113);

//     width: 100%;

//     > div {
//         margin: 2rem 0 2rem 0;
//     }

//     h2 {
//         font-size: 1.5rem;
//     }

//     .left {
//         // margin: 2rem 0 0 4rem;
//         position: relative;
//         h3 {
//             margin: 1rem 0 0 0;
//         }

//         .copyright {
//             position: absolute;
//             bottom: 3rem;
//         }
//     }

//     .right {
//         // margin: 2rem 0 0 4rem;
//         position: relative;
//         .social-media {
//             // margin: 1rem 0 0 0;
//             // height: 100px;
//             position:absolute;
//             bottom: 3rem;
            
//             a {
//                 padding: 0 1rem 0 0;
//                 text-decoration: none;
//                 color: black;
//                 width: 5rem;
//                 height: 5rem;
//             }
//         }
//     }

// }

.footer {
    min-height: 12rem;
    background-color: rgb(113, 113, 113);
    margin-left: auto;
    margin-right: auto;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        > div {
            padding: 2rem 0 2rem 2rem;
            h2 {
                font-size: 1.5rem;
            }
        }
        .left {
            width: calc(max(50%, 360px));
            .social-media {
                margin-top: 3rem;
                a {
                    text-decoration: none;
                    color: black;
                    padding: 0 12px 0 0;
                }
            }
        }
        .right {
            width: calc(max(50%, 360px));
            h2 {
                padding-bottom: 0.5rem;
            }
            a {
                text-decoration: none;
                color: black;
                border-bottom: 1px dotted #000;
                cursor: pointer;
            }
            .copyright {
                padding-top: 2.5rem;
            }
        }
    }
}