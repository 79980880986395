.Intro {
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  padding-bottom: 3rem;
  background-image: url(face2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Intro h1 {
  font-size: 4rem;
  margin: 2rem 0 0 0;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-right: 12px solid rgba(36, 36, 36, 0.75);
  white-space: nowrap;
  overflow: hidden;
}

.Intro .anagramDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 4rem;
  cursor: default;
}

@media only screen and (max-width: 993px) {
  .Intro .anagramDiv {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.Intro .char {
  font-size: 3rem;
  margin: 0.1rem;
}

.Intro .space {
  padding: 1rem;
}

.Intro .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 4rem;
}

.Intro .container .texts {
  height: 360px;
  width: 760px;
}

@media only screen and (max-width: 993px) {
  .Intro .container .texts {
    width: 75vw;
    height: 100%;
  }
}

.Intro .container .texts span {
  font-size: 5rem;
  font-weight: bold;
}

@media only screen and (max-width: 1224px) {
  .Intro .container .texts span {
    font-size: calc(min(10vw, 5rem));
  }
}

.Intro .container .smallScreenTexts {
  text-align: center;
  font-weight: bold;
}

.Intro .container .smallScreenTexts .name {
  display: block;
  font-size: 12vw;
}

.Intro .container .smallScreenTexts .details {
  display: block;
  font-size: 6vw;
  margin-top: 16vw;
}

.Intro .container .postAnimationName {
  position: absolute;
  font-size: 5rem;
  font-weight: bold;
}

.Intro .container .postAnimationDetails {
  position: absolute;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

.Intro .anagramDiv > div {
  font-size: 5rem;
  font-weight: bold;
  position: relative;
}

@media only screen and (max-width: 993px) {
  .Intro .anagramDiv > div {
    font-size: 8vw;
  }
}

.Intro .cursor {
  color: grey;
}
