.footer {
  min-height: 12rem;
  background-color: #717171;
  margin-left: auto;
  margin-right: auto;
}

.footer .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.footer .container > div {
  padding: 2rem 0 2rem 2rem;
}

.footer .container > div h2 {
  font-size: 1.5rem;
}

.footer .container .left {
  width: calc(max(50%, 360px));
}

.footer .container .left .social-media {
  margin-top: 3rem;
}

.footer .container .left .social-media a {
  text-decoration: none;
  color: black;
  padding: 0 12px 0 0;
}

.footer .container .right {
  width: calc(max(50%, 360px));
}

.footer .container .right h2 {
  padding-bottom: 0.5rem;
}

.footer .container .right a {
  text-decoration: none;
  color: black;
  border-bottom: 1px dotted #000;
  cursor: pointer;
}

.footer .container .right .copyright {
  padding-top: 2.5rem;
}
