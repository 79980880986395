.skills {
  background-color: #c7c7c7;
  padding-bottom: 6rem;
  position: relative;
  z-index: 10;
}

.skills .container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.skills .container h2 {
  padding: 4rem;
  font-size: 2rem;
}

.skills .container .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.skills .container .icons > * {
  margin: 1rem;
}

.skills .container .icons ~ div .tooltip-text {
  visibility: hidden;
}

.skills .container .icons .icon {
  width: 200px;
}

.skills .container .icons .icon > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.skills .container .icons .icon .icon-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem;
}
