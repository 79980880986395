* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

.NavBar {
  background: grey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  width: 100%;
  top: 0%;
  z-index: 1000;
}

.NavBar .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 4rem;
}

.NavBar .name p {
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
}

.NavBar .name img {
  width: 2.5rem;
  height: 2.5rem;
}

.NavBar .menu-list {
  margin-right: 3rem;
}

.NavBar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.NavBar li {
  list-style: none;
  margin: 1rem;
}

.NavBar li div {
  color: black;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 1.2rem;
}

.NavBar li div:hover {
  font-weight: 800;
}

.NavBar li div::after {
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  font-weight: 800;
}

.NavBar button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  background-color: white;
  border-color: black;
  color: black;
}

.NavBar button:hover {
  background-color: #dedede;
  border-color: black;
  cursor: pointer;
  font-weight: 800;
}

.NavBar button a {
  font-size: 1.1rem;
  color: black;
  text-decoration: none;
}

.NavBar button::after {
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  font-weight: 800;
}

.NavBar .burger {
  display: none;
}

@media screen and (max-width: 1000px) {
  .NavBar {
    height: 6rem;
  }
  .NavBar .menu-list {
    position: absolute;
    right: 0%;
    margin: 0;
    height: calc(100vh - 4rem);
    top: 6rem;
    background-color: grey;
    width: 50%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.5s ease-in;
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  }
  .NavBar .menu-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .NavBar .burger {
    display: inline;
    margin-right: 4rem;
    cursor: pointer;
  }
}

.NavBar .nav-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

@media screen and (max-width: 600px) {
  .NavBar .name {
    margin-left: 2rem;
  }
  .NavBar .burger {
    margin-right: 2rem;
  }
}
