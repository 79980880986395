.Intro {
    // max-width: 1980px;
    // max-width: 100px;
    // width: 100%;
    // width: 100vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
    padding-bottom: 3rem;
    background-image: url(face2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    h1 {
        font-size: 4rem;
        margin: 2rem 0 0 0;
        text-align: left;
        width: fit-content;
        border-right: 12px solid rgba(36, 36, 36, 0.75);
        white-space: nowrap;
        overflow: hidden;
    }

    .anagramDiv {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;
        cursor: default;
        @media only screen and (max-width : 993px) {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }
    
    .char {
        font-size: 3rem;
        margin: 0.1rem;
    }

    .space {
        padding: 1rem;
    }
    
    .container {
        display: flex;
        justify-content: center;
        padding-top: 4rem;

        .texts {
            height: 360px;
            width: 760px;
    
            @media only screen and (max-width : 993px) {
                width: 75vw;
                // height: 480px;
                height: 100%;
            }

            span {
                font-size: 5rem;
                font-weight: bold;

                @media only screen and (max-width : 1224px) {
                    font-size: calc(min(10vw, 5rem));
                }
            }   
        }

        .smallScreenTexts {
            text-align: center;
            font-weight: bold;
            .name {
                display: block;
                font-size: 12vw;
            }
            .details {
                display: block;
                font-size: 6vw;
                margin-top: 16vw;
            }
        }

        .postAnimationName {
            position: absolute;
            font-size: 5rem;
            font-weight: bold;
        }
        .postAnimationDetails {
            position: absolute;
            font-size: 2rem;
            text-align: center;
            font-weight: bold;
        }
    }

    .anagramDiv > div {
        font-size: 5rem;
        font-weight: bold;
        position: relative;
        @media only screen and (max-width : 993px) {
            font-size: 8vw;
        }
    }

    .cursor {
        color: grey;
    }
    
}