.About {
    // max-width: 1980px;
    position: relative;
    background-color: rgb(199, 199, 199);
    z-index: 10;
    padding-bottom: 4rem;

    h2 {
        padding: 4rem 4rem 2rem 4rem;
        font-size: 2rem;
    }

    .container {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    p, a{
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
        padding-top: 2rem;
        padding-bottom: 2rem;
        font-size: 1.2rem;
        @media only screen and (max-width : 1100px) {
            padding: 2rem 2rem 4rem 2rem;
        }
    }

    a {
        text-decoration: none;
        color: black;
        border-bottom: 1px dotted #000;
        cursor: pointer;
        padding: 0;
    }

    span {
        font-size: 1.2rem;
        font-weight: 900;
    }
}