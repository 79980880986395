.skills {
    background-color: rgb(199, 199, 199);
    padding-bottom: 6rem;

    position: relative;
    z-index: 10;

    .container {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;

        h2 {
            padding: 4rem;
            font-size: 2rem;
        }

        .icons{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            > * {
                margin: 1rem;
            }

            & ~ div {
                .tooltip-text {
                    visibility: hidden;
                }
            }

            .icon {
                width: 200px;
                > * {
                    display: flex;
                    justify-content: center;
                    text-align: center;
                }

                .icon-name {
                    font-size: 1.2rem;
                    font-weight: bold;
                    margin: 0.5rem;
                }
                

            }
            


        }


    }
    
}